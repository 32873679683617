import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chairs',
  templateUrl: './chairs.component.html',
  styleUrls: ['./chairs.component.scss']
})
export class ChairsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
